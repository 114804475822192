.cardsHolder {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-wrap: wrap;
}

.section {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
}

.section h2 {
	margin: 2rem 0 1rem;
}