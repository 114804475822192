.section {
  width: 100%;
  height: 80vh;
  background-image: url('../../../img/about-welcome-section.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section_children {
  width: 80%;
}
