.section {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
}

.section img {
	width: 40%;
	min-width: 350px;
	max-height: 300px;
	margin: 5px;
	cursor: pointer;
	transition: 0.2s;
}

.section h2 {
	text-align: center;
	margin: 2rem 0;
}

.images_holer {
	width: 90%;
	margin: auto;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}