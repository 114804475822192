.card {
	text-decoration: none;
	width: 24%;
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: 0.2s ease;
	border-radius: 5px;
	border: solid 1px transparent;
}

.card * {
	transition: 0.2s ease;
}

.card:hover {
	cursor: pointer;
	color: white;
	background: rgb(40, 40, 40);
}

.card:active * {
	transform: scale(0.95);
}

.card p {
	font-weight: 600;
}

.price {
	font-size: 18px;
	color: #C3073F;
}

.img_holder {
	width: 90%;
}

.img {
	pointer-events: none;
	width: 100%;
}

