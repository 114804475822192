.section {
	width: 100%;
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.slider_arrows {
	font-size: 24px;
	padding: 0.2rem 0.5rem;
	transition: 0.2s ease;
	margin: 0 0.5rem;
	border: solid 1px white;
}

.slider_arrows:hover {
	background: white;
	color: black;
}


.slider_arrows:active {
	background: white;
	color: black;
	user-select: none;
	filter: brightness(70%);
}

.card_slider {
	display: flex;
	width: 95vw;
	overflow: hidden;
	user-select: none;
	margin: 2rem 0 1rem 0;
}

.slider_line {
	display: flex;
	position: relative;
	transition: 0.5s ease-in-out;
}

.card_groups_holder {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.cards_holder {
	width: 95vw;
	display: flex;
	justify-content: space-around;
}