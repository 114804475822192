.header {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	transition: 0.1s ease-in;
	z-index: 100;
}

.logo {
  width: 80px;
	margin-left: 2rem;
}

.content_holder {
	display: flex;
	align-items: center;
	margin-right: 2rem;
}

.content_holder * {
	margin: 0.4rem;
	color: white;
	transition: 0.2s ease-in-out;
}

.link_holder {
	display: flex;
}

.link_holder a {
	text-decoration: none;
	position: relative;
}

.link_holder a::before {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0px;
	width: 0;
	height: 1.2px;
	background-color: white;
}

.link_holder a:hover:before {
	width: 100%;
}

.link_holder a::before {
	transition: width 0.35s;
}