.myModal {
	z-index: 110;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: none;
	background: rgba(0,0,0, 0.9);
}

.myModal.active {
	display: flex;
	justify-content: center;
	align-items: center;
}

.myModalContent {
	padding: 25px;
	background: white;
	width: 90%;
	display: flex;
	flex-direction: column;
}

.myModalContent input, textarea {
	border: 1px black solid;
}

.myModalContent input::selection, textarea::selection {
	background-color: rgb(230, 120, 120);
}

.myModalContent input:focus, textarea:focus {
	outline: #C3073F 3px solid;
	border: 1px transparent solid;
	border-radius: 2px;
}

.top_bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.top_bar h3 {
	color: black;
}

.closeBtn {
	color: rgb(150, 150, 150);
	cursor: pointer;
	font-size: 24px;
	transition: 0.2s;
}

.closeBtn:hover {
	color: rgb(80, 80, 80);
}

.myModalContent input {
	margin: 1rem 0;
	padding: 1rem;
	font-size: 18px;
	font-weight: 500;
}

.textarea {
	resize: none;
	margin: 1rem 0;
	padding: 1rem;
	min-height: 250px;
	font-size: 16px;
	font-family: Arial, Helvetica, sans-serif;
}

.textarea::-webkit-scrollbar {
	background: white;
	cursor: pointer;
}

.bottom_bar {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.bottom_bar input {
	padding: 0.5rem;
}

.bottom_bar button {
	background: #C3073F;
	border: none;
	padding: 0.5rem 15px;
	cursor: pointer;
	color: white;
	font-size: 14px;
	font-weight: 600;
	margin: 0 10px;
	transition: 0.2s;
}

.bottom_bar button:hover {
	filter: brightness(0.8);
}

.bottom_bar button:active {
	transform: scale(1.1);
}

.stars_holder {
	color: #C3073F;
	margin: 0.5rem 1rem;
	font-size: 20px;
	transition: 0.2s;
}

.star {
	cursor: pointer;
}

.checkText {
	color: rgb(175, 0, 0);
	font-weight: 600;
	display: none;
	animation: checkTextAnimation 0.1s;
}

@keyframes checkTextAnimation {
	from {
		transform: scale(0);
	} to {
		transform: scale(1);
	}
}