.section {
  width: 100%;
  height: 80vh;
  background-image: url('../../../img/coaches-welcome-section.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  color: white;
  display: flex;
  align-items: center;
  justify-content: start;
}

.section_children {
  width: 50%;
  margin-left: 10vw;
}