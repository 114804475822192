.card {
	width: 80%;
	margin: 0 auto;
	display: flex;
	justify-content: space-;
}

.card p {
	color: rgb(230, 230, 230);
}

.imgHolder {
	padding: 20px;
}

.img {
	width: 200px;
}

.infoHolder {
	padding: 20px;
}

.infoHolder a {
	text-decoration: none;
	transition: 0.2s;
	color: rgb(205, 30, 75);
}

.infoHolder a:hover {
	color: white;
}