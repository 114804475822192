.section {
  width: 100%;
  height: 100vh;
  color: white;
}

.section_children {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
}

.textHolder {
  margin-left: 10vw;
}

.video_holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

}

.video {
  width: 100%;
  filter: brightness(0.7);
}