.second_section {
	width: 95%;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem 0;
	text-align: center;
}

.second_section h2 {
	font-size: 34px;
	margin-bottom: 1rem;
}

.second_section p {
	margin: 1rem 0;
	color: #7a7a7a;
	font-size: 14px;
}

.item_holder {
	margin: 1rem 0;
	display: flex;
	width: 100%;
	justify-content: space-evenly;
}

.item {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.item p {
	font-weight: 600;
	font-size: 20px;
	color: white;
}

.item svg {
	color: #C3073F;
	font-size: 50px;
}